/* Here we define the lower and upper bounds for each media size*/
$small-range: (0em, 30em); /* 0, 480px */
$medium-range: (30.063em, 48em); /* 481px, 768px */
$large-range: (48.063em, 63.938em); /* 769px, 1024px */
$xlarge-range: (64em, 90em); /* 1025px, 1440px */
$xxlarge-range: (90.063em); /* 1441px */

/* We use these functions to get the ranges for the media queries variables.*/
@function lower-bound($range){
    @if length($range) <= 0 {
        @return 0;
    }
    @return nth($range,1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

/* Media Queries*/
$screen:       "only screen";

$landscape:    "#{$screen} and (orientation: landscape)";
$portrait:     "#{$screen} and (orientation: portrait)";

$small-up:        $screen;
$small-only:   "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up:    "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-down:  "#{$screen} and (max-width:#{upper-bound($medium-range)})";
$medium-only:  "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up:     "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-down:   "#{$screen} and (max-width:#{upper-bound($large-range)})";
$large-only:   "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up:    "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-down:  "#{$screen} and (max-width:#{upper-bound($xlarge-range)})";
$xlarge-only:  "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up:   "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-down: "#{$screen} and (max-width:#{upper-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";


body {
  color: #414549;
}

/* TEMPORÄR HEADER WEG*/
header {
  /* display: none;*/
}

a {
  color: #008085;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat', sans-serif;
}

p {
  font-size: 0.875rem;
  word-break: break-word;
  @media #{$medium-down} {
    /* font-size: 0.75rem;*/
    /* line-height: 1.2rem;*/
  }
}

.upperheader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
    -ms-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    /*box-shadow: 0px 1px 1px rgba(0,0,0,0.3);*/
    max-width: 1260px;
    max-width: 78.75rem;
    background: #fff;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
}

.upperheader-wrapper {
    border-bottom: 1px solid rgba(51, 51, 51, 0.22);
    -webkit-box-shadow: 0px -10px 13px 5px black;
            box-shadow: 0px -10px 13px 5px black;
    @media #{$medium-down}{
      /* display: none;*/
    }
}

.header--navigation {
  background: transparent;
  padding: 1rem 0rem !important;
  @media (min-width:1025px) and (max-width: 1280px) {
    padding: 1rem 20px !important;
  }
}

.header--navigation-fixed {
  position: fixed !important;
  top: 0;
  z-index: 7999;
  background: white;
  max-width: 100%;
}

.header--navigation-fixed .lowerheader {
  width: 100%;
  max-width: 1260px;
  max-width: 78.75rem;
  margin: 0 auto;
}

.top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.top-bar--language {
  height: 100%;
  margin: 0 1% 0 3%;
  /* border-right: 1px solid #D7D7D7;*/
  padding-right: 1%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.top-bar--language .field--select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.top-bar--language .js--fancy-select {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: none;
  max-height: none;
  background-image: none;
  background-color: transparent;
  border: none;
}

.top-bar--language .js--fancy-select .js--fancy-select-text {
  padding: 0;
  font-size: 0.75rem;
  @media #{$large-up}{
    line-height: 32px;
  }
}

.top-bar--language .js--fancy-select .js--fancy-select-trigger {
  position: relative;
  border-left: none;
  @media #{$large-up}{
    line-height: 19px;
    background: #5C6165;
    border-radius: 50%;
    margin-left: 5px;
    color: white;
    width: 18px;
    height: 18px;
  }
}

.top-bar--language .js--fancy-select .js--fancy-select-trigger i {
  font-size: 4.1px;
}

.top-bar .service--entry .service--link {
  font-size: 0.75rem;
  border-right: 1px solid #7B8080;
  padding: 0 10px;
}

.upperheader .top-bar--language .language--flag {
  margin-right: 10px;
}

#support .js--fancy-select {
  max-height: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 19px;
  line-height: 1.1875rem;
  font-size: 14px;
  font-size: .875rem;
  width: 100%;
  max-width: 100%;
  padding: 10px 10px 9px 10px;
  padding: .625rem .625rem .5625rem .625rem;
  /* box-shadow: inset 0 1px 1px #dadae5; */
  background: #f8f8fa;
  border: none;
  border-top-color: #cbcbdb;
  color: #8798a9;
  text-align: left;
  border-bottom: 1px solid #dadae5;
  font-style: italic;
}

.entry--search {
  position: relative;
  left: 0;
  list-style: none;
  @media #{$large-only} {
    width: 25%;
  }
  height: 100%;
}

.entry--search .main-search--form .main-search--button {
  background-color: #801E50;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  top: 8px;
  right: 10px;
}

.entry--search .main-search--form .main-search--button i {
  position: absolute;
  top: 7px;
  right: 7px;
}

.entry--search .main-search--form .main-search--field {
  background: #D7D7D7;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0px;
  border-radius: 0;
  height: 100%;
  font-style: italic;
  padding: 0.5625rem 1.5rem;
}

.entry--search .main-search--form .main-search--field:focus {
  background: #D7D7D7;
}

.entry--search .main-search--form {
  -webkit-box-shadow: 0 0 0 transparent;
          box-shadow: 0 0 0 transparent;
  border-bottom: 0 none;
  padding: 0;
  height: 100%;
  display: inline-block;
  position: relative;
}

.header-main .top-bar {
  float: right;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.service--list {
  width: 100%;
  max-width: 700px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  @media #{$large-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.lowerheader .service--list, .lowerheader .productmenu-button, .upperheader .service--list {
  @media #{$large-down} {
    display: none;
  }
}

.socialbuttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  display: none;
  @media #{$small-only} {
    display: none;
  }
}

.socialbuttons li {
  list-style-type: none;
  margin-right: 5px;
}

.socialbuttons li img {
  width: 20px;
  margin: 5px;
}

.top-bar .is--rounded {
  line-height: 31px;
}

.is--rounded li {
  list-style: none;
  /* padding: 0 10px;*/
  /* border-right: 1px solid #D7D7D7;*/
}

.is--rounded li:last-of-type {
  border-right: none;
}

.is--rounded li a {
  color: #7B8080;
}

#storeLocator .js--fancy-select {
  max-height: none;
}

.js--fancy-select {
  max-width: 10rem;
  max-height: 1.7rem;
}

.js--fancy-select.js--is--focused {
  color: #008085;
  border-color: #008085;
}

.js--fancy-select:hover {
  color: #008085;
  border-color: #008085;
}

.is--primary {
  background-color: #008085;
  background-image: none;
}

.is--primary:hover {
  background-color: #008085;
  background-image: none;
}

.btn:hover {
  border-color: #008085;
  /* color: #008085;*/
}

.lowerheader .service--entry {
  padding: 0.3rem 0.5rem;
  border: none;
  background-color: white;
  line-height: 30px;
}

.lowerheader {
  /* margin-top: 20px;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (min-width: 820px) and (max-width: 1024px) {
  .lowerheader {
    padding: 0 20px;
  }
}

.lowerheader .navigation--list-wrapper {
  max-width: 1260px;
  max-width: 78.75rem;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100vw;
  max-height: 80vh;
  overflow: auto;
  display: none;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 20px 10px 10px 10px;
  z-index: 9999;
  background: rgb(239,239,239);
  background: -webkit-linear-gradient(top, rgba(239,239,239,1) 0%,rgba(219,219,219,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(239,239,239,1)),to(rgba(219,219,219,1)));
  background: linear-gradient(to bottom, rgba(239,239,239,1) 0%,rgba(219,219,219,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#dbdbdb',GradientType=0 );
}

.lowerheader .navigation--list-wrapper:hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.lowerheader .navigation--list-wrapper .navigation--list {
  background-color: transparent;
}

.lowerheader .main-menu-wrapper {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
      -ms-flex-wrap: nowrap;
          -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.lowerheader .product-navigation--list {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
      -ms-flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

/* .lowerheader .navigation--list .navigation--list-wrapper .navigation--list {*/
/*   -ms-flex-wrap: wrap;*/
/*       flex-wrap: wrap;*/
/* }*/

.lowerheader .shop--navigation .navigation--link{
  float: left;
  background-color: #FFFFFF;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#ffffff',GradientType=0 );
  list-style-type: none;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding: 0;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #414549;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  word-break: break-word;
}

.lowerheader .shop--navigation .navigation--link:hover {
  background: rgb(226,226,226);
  background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%,rgba(255,255,255,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(226,226,226,1)),to(rgba(255,255,255,1)));
  background: linear-gradient(to bottom, rgba(226,226,226,1) 0%,rgba(255,255,255,1) 100%);
}

.lowerheader .shop--navigation .navigation--link img {
  width: 100px;
  height: 100px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          -webkit-flex: 0 0 40%;
          flex: 0 0 40%;
  -o-object-fit: contain;
     object-fit: contain;
}

.lowerheader .shop--navigation .navigation--link span {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 100px);
          -webkit-flex: 0 0 -webkit-calc(100% - 100px);
          flex: 0 0 calc(100% - 100px);
  text-align: left;
  padding: 20px 15px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  -ms-flex-item-align: start;
      -webkit-align-self: flex-start;
          align-self: flex-start;
}

.lowerheader .navigation--entry {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(25% - 20px);
          -webkit-flex: 0 0 -webkit-calc(25% - 20px);
          flex: 0 0 calc(25% - 20px);
  min-width: 23%;
  margin: 8px;
  right: 0;
  /* position: absolute;*/
  left: auto;
  @media #{$large-down} {
    margin: 0 20px;
  }
}

.btn.is--icon-left [class^="icon--"] {
  right: 0;
}

.header-main .logo-main .logo--shop {
  @media #{$medium-down} {
    width: auto;
  }
}

.menu_item_pflegeberater {
  background-color: white;
  padding: 20px;
  text-align: left;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(50% - 20px);
          -webkit-flex: 0 0 -webkit-calc(50% - 20px);
          flex: 0 0 calc(50% - 20px);
  margin: 20px 10px;
}

.menu_item_pflegeberater h3 {
  margin: 10px 0;
}

.menu_item_pflegeberater h4 {
  margin: 0;
}

.header-main {
  padding-bottom: 0;
  border: none;
}

.header-main .logo-main {
  /* width: 25%;*/
  -webkit-box-flex: 0;
      -ms-flex: 0 0 18%;
          -webkit-flex: 0 0 18%;
          flex: 0 0 18%;
  @media #{$large-down}{
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 55%;
            -webkit-flex: 0 0 55%;
            flex: 0 0 55%;
    padding: 0 20px;
  }
}
.header-main .shop--navigation {
  /* width: 75%;*/
  -webkit-box-flex: 0;
      -ms-flex: 0 0 82%;
          -webkit-flex: 0 0 82%;
          flex: 0 0 82%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
  @media #{$large-down}{
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            -webkit-flex: 0 0 45%;
            flex: 0 0 45%;
  }
}

.shop--navigation .entry--menu-left .btn {
  font-size: 0;
}

.productmenu-button {
  width: 140px;
  background-color: #7B8080;
  color: white;
  border: none;
  font-weight: bold;
  padding: 9px 13px;
  line-height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  @media #{$medium-down} {
    /* display: none;*/
  }
  @media (min-width: 1040px) {
    margin-right: 20px;
  }
}

.productmenu-button:hover {
  background-color: #00808A;
}

.productmenu-button img {
  -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
      transform: rotate(0deg);
}

.productmenu-button:hover img {
  -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}

.productmenu-button:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 55px;
  width: 0;
  height: 0;
  border-top: solid 10px transparent;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  z-index: 99999;
}

.productmenu-button:hover:after {
  border-top: solid 10px #008085;
}

.productmenu-button img {
  height: 21px;
  width: 21px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      -webkit-align-self: center;
          align-self: center;
  margin-right: 10px;
  @media #{$medium-down} {
    position: absolute;
    right: 20px;
  }
}

.productmenu-button:hover ~ .navigation--list-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.shop--navigation .service--link {
  color: #5D6266;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.handler-button{
  background-color: #801E50;
  color: white;
  padding: 10px 10px 10px 20px;
  position: relative;
  margin: 35px 0;
  border: none;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.handler-button b {
  font-size: 15px;
  font-weight: bold;
}

.handler-button img {
  position: absolute;
  right: 12px;
  top: 7px;
  max-height: 25px;
  max-width: 25px;
}

.footer-main {
  width: 100%;
  margin-top: 0px;
  border: none;
  /* @media (max-width: 1260px){*/
  /*   padding: 0 20px;*/
  /* }*/
}

.footer--info {
  background-color: #5C6165;
  color: #fefefe;
  padding: 50px;
  @media #{$medium-down} {
    padding: 20px;
  }
}

.footer--info .info--headline {
  font-size: 1.5625rem;
  margin: 0 2.5% 1.5rem 2.5%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.footer--info .info--paragraph {
  margin: 0 2.5% 1.5rem 2.5%;
  line-height: 2;
}

.footer-main .footer--columns {
  background-color: #212121;
  color: #8B8B8B;
  padding: 0px;
  border-bottom: none;
}

.footer--columns a {
  color: #8B8B8B !important;
}

.footer--columns hr {
  margin: 2px auto;
  opacity: 1;
  background: #2D2D2D;
  border: none;
  height: 1px;
}

.footer--columns hr:last-of-type {
  display: none;
}

.footer-main .column--headline {
  margin: 3.75rem 0rem 1.625rem 0rem;
  font-size: 1.25rem;
  color: #8B8B8B;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  @media #{$large-down} {
    margin: 1.625rem 0rem 1.625rem 0rem
  }
}

.footer--claim {
  background-color: #191919;
  padding: 75px;
  color: #00B9CB;
  text-align: center;
  @media #{$small-only} {
    padding: 40px;
  }
}

.claim--headline {
  font-size: 1.9375rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  @media #{$small-only} {
    font-size: 1.3rem;
  }
}

.footer--bottom {
  background-color: #fefefe;
  padding: 10px;
  height: 60px;
  text-align: center;
}

.footer--copyright {
  color: #4C4C4C;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.footer--columns .container, footer .container {
  background: transparent;
  background-color: transparent;
}

.footer--columns>.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.footer-main .footer--column {
  width: 20%;
  margin: 2.5%;
}

@media (max-width: 1023px) {
  .footer-main .footer--column {
    width: 46%;
    margin: 2%;
  }
}

@media (max-width: 480px) {
  .footer-main .footer--column {
    width: 100%;
    margin: 0%;
    padding: 20px;
  }
}

.emotion--wrapper {
  max-width: 100%;
}

/* .emotion--category-teaser .category-teaser--link{*/
/*   background-size: contain;*/
/* }*/

.image-slider--thumbnails {
  @media #{$xlarge-up}{
    width: 4.375rem;
    height: 100%;
    margin: 0rem 1.25rem 0rem 0rem;
    position: relative;
    top: 0px;
    left: 30px;
    float: left;
    overflow: auto;
    white-space: normal;
  }
}

.image-slider--thumbnails .thumbnails--.is--top {
  bottom: 0;
}

.image-slider--thumbnails .image-slider--thumbnails-slide {
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.image-slider--thumbnails .thumbnail--link {
  @media #{$xlarge-up} {
    margin: 0px 0px 20px 0px;
    margin: 0rem 0rem 1.25rem 0rem;
  }
}

.emotion--banner-slider .image-slider--dots .dot--link {
  width: 5rem;
  height: 0.3rem;
  border: none;
  border-radius: 0;
  background: #E8E8E8;
  opacity: 0.5;
  font-size: 0;
  @media #{$xlarge-down}{
    /* margin: 20px;*/
  }
  @media #{$medium-down} {
    width: 4rem;
    margin: 10px
  }
  @media #{$small-only} {
    width: 3rem;
    height: 0.1rem;
    margin: 5px;
  }
}

.emotion--banner-slider .image-slider--dots .dot--link.is--active {
  background: #E8E8E8;
  opacity: 0.9;
  border-radius: 15px;
  margin: 0 10px;
}

.logo--link img {
  /* width: 100%;*/
}

.custom-page--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media #{$medium-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 0;
  }
}

.content--custom {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  border: 2px solid #EFEFEF;
  padding: 40px;
  line-height: 1.5;
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    /* margin-bottom: 50px;*/
    padding: 20px;
  }

  td {
    padding: 0;
  }

  li {
    margin-bottom: 10px;
  }

  ul, ol {
    padding-left: 17px;
  }

  h1 {
    margin-top: 0;
    font-size: 24px;
    color: #008088;
    font-weight: 300;
  }
}

.content--custom.blog--listing {
  border: none;
  padding: 0;
  margin-top: 0;
}

.custom-sidebar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    padding: 0px;
    margin-top: 50px;
  }
}

.custom-sidebar a span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.custom-sidebar a span img {
  max-height: 16px;
  margin-right: 5px;
}

/* .custom-sidebar p:nth-child(4n+3)::before {*/
/*   content:url('/media/unknown/81/b5/db/chevron_right_circle_red.svg');*/
/*   height: 16px;*/
/*   display: inline-block;*/
/* }*/

.custom-sidebar p:nth-child(2), .custom-sidebar p:nth-last-child(+2){
  margin-bottom: 10px;
}

.page-wrap {
  background-color: white;
}

.content-main {
  -webkit-box-shadow: none;
          box-shadow: none;
  /* margin-top: 2.8125rem;*/
  margin-bottom: 2.8125rem;
  @media (max-width: 1260px){
    padding: 0 20px;
  }
}

.content-main--inner {
  padding: 0;
  @media screen and (max-width: 1260px) {
    /* padding: 0 20px;*/
  }
}

.header-main .logo-main .logo--shop {
  float: right;
  @media #{$large-down}{
    float: none;
  }
}

.content--breadcrumb {
  border-bottom: none;
  background-color: #FAFAFA;
  -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
          box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  @media #{$medium-down} {
    display: none;
  }
}

.content--breadcrumb .breadcrumb--button {
  background: #801E50;
  padding: 0px 5px;
  border-radius: 5px;
  color: white;
  margin: 7px 10px 7px 0px;
  padding: 3px 10px;
}

.content--breadcrumb .breadcrumb--link:hover {
  color: #008085;
}

.content--breadcrumb .is--active .breadcrumb--link {
  color: #7B8080;
}

/* .custom-content-banner .banner-image {*/
/*   position: relative;*/
/* }*/
/**/
/* .custom-content-banner .banner-image p {*/
/*   margin: 0;*/
/* }*/
/**/
/* .custom-content-banner .banner-text {*/
/*   position: absolute;*/
/*   top: 15%;*/
/*   left: 5%;*/
/*   text-shadow: 0px 0px 20px rgba(0, 0, 0, .4);*/
/* }*/
/**/
/* .custom-content-banner .banner-text p:first-child {*/
/*   font-size: 3vw !important;*/
/*   line-height: 3vw;*/
/*   *//*font-weight: bold;*//**/
/* }*/
/**/
/* .content--custom p img {*/
/*   @media #{$small-only} {*/
/*     display: none;*/
/*   }*/
/* }*/

/*.content-main--inner .content--wrapper .product--details .product--info {
  height: 80px;
  background-color: #008085;
}*/

.product--details .product--header .product--title {
  color: white;
  line-height: 60px;
  font-size: 1.35rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  @media #{$medium-down} {
    line-height: 30px;
    padding: 10px;
  }
}

.product--details .product--header {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 3rem;
}

.product--details .block-group {
  background: #eeeeee;
  background: -webkit-linear-gradient(top, #eeeeee 5%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #eeeeee), to(#ffffff));
  background: linear-gradient(to bottom, #eeeeee 5%, #ffffff 100%);
  border: 1px solid #eeeeee;
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  @media #{$large-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.shareicon-wrapper {
  position: absolute;
  bottom: 10px;
  right: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.shareicon-wrapper p {
  margin-bottom: 0;
}

.shareicon-wrapper img {
  max-height: 20px;
  margin-left: 10px;
}

.product--details .product--buybox {
  background-color: #ffffff;
  margin: 25px;
  padding: 25px;
  @media #{$small-only} {
    margin: 0px;
    padding: 20px;
  }
}

.product--details .product--image-container {
  margin-right: 0;
  max-height: 500px;
  @media #{$small-only} {
    max-height: 350px;
  }
}

.product--details .product--image-container .image--media img {
  display: inline-block;
  height: 100%;
}

.product--buybox .product--description ul li {
  border-top: 1px solid #F5F5F5;
  line-height: 25px;
  margin: 15px 0px;
  font-weight: 700;
  list-style: none;
  padding-left: 30px;
  position: relative;
}

.product--buybox .product--description ul li:before {
  content: '';
  display: inline-block;
  height: 15px;
  background-size: contain;;
  width: 15px;
  margin-right: 10px;
  background-image: url(/media/unknown/2d/68/01/check-gray.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 5px;
  left: 0px;
}



/* EINKAUFSWELTEN */

.text-banner-slider-home {
  z-index: 1000 !important;
  background: rgba(255,255,255,0.95) !important;
  @media #{$large-up} {
    height: 245px;
    margin-top: -225px;
  }

  .html--content {
    padding: 0 10px;
  }

  p {
    font-size: 18px;
    @media #{$medium-down} {
      font-size: 14px;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: 300;
    @media #{$medium-down} {
      font-size: 20px;
    }
  }
}

@media (max-width: 767px) {
  .emotion--banner-slider .text-banner-slider-home {
    /* top: 15% !important;*/
  }
}

.emotion--banner-slider .text-banner-slider-home {
  padding-left: 3.125rem;
  padding-bottom: 1.125rem !important;
  padding-top: 30px !important;
  /*height: 8.33333% !important;*/
  top: 20% !important;
  z-index: 9999999 !important;
  background: rgba(255,255,255,0.95) !important;
}

.emotion--banner-slider .image-slider--dots {
  bottom: 220px;
  /*bottom: 16.625rem !important;*/
  top: auto;
  left: 0;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 0;
  @media #{$xlarge-down} {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
  }
}

.emotion--banner-slider .slider-home {
  padding-left: 3.125rem;
  padding-bottom: 3.125rem;
  height: 30% !important;
  top: 0% !important;
}


.category_teaser_link_button {
    background-color: #7B8080;
    color: #fefefe;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 0.9375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    max-width: 60%;
}

.category_teaser_link_button img {
  display: inline;
  height: 26px;
  margin: 7px;
}

.category_teaser_link_button p {
  margin: 20px 0;
  max-width: 70%;
}

.category_teaser_link_image {
  position: absolute;
  right: 0;
  max-width: 40%;
}

/* .emotion--category-teaser {*/
/*   *//*background: linear-gradient(0deg, lime 0%, #fcfcfc 57.44%, red 100%);*//**/
/*   overflow: hidden;*/
/*   *//* display: flex;*//**/
/* }*/

.emotion--category-teaser {
  background: #f3f3f3;
  background: -webkit-linear-gradient(top, #f3f3f3 0%,#ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#f3f3f3),to(#ffffff));
  background: linear-gradient(to bottom, #f3f3f3 0%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#ffffff',GradientType=0 );
}

.emotion--category-teaser .category-teaser--title {
  background: none;
  position: relative;
}

/* TEST */

.card{
  border-style: Solid;
  border-color: #F3F3F3;
  border-color: #f3f3f3;
  border-width: 1px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F3F3F3',endColorstr='#FFFFFF' , GradientType=0);
  position: relative;
  padding: 20px;
  margin-bottom: 0px;
  background: -webkit-linear-gradient(bottom, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  background: -webkit-gradient(linear, left bottom, left top, from(white), color-stop(57.44%, #fcfcfc), to(#f3f3f3));
  background: linear-gradient(0deg, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  /* display: flex;*/
  /* flex-wrap: nowrap;*/
}

.card h5 {
  line-height: 1;
  /*max-width: 70%;*/
}

.card-text-wrapper {
  position: relative;
  z-index: 2;
}

.pflegeberaterBox .button-gray {
  width: 100%;
}

.pflegeberaterBox .button-gray p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.pflegeberaterBox h5 {
  margin-top: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 20px;
}

.pflegeberaterBox {
  padding: 20px;
  background: #F2F2F2;
  @media #{$large-down} {
    display: none;
  }
}

.pflegeberaterBox p {
  line-height: 1.3rem;
}

.card > img {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
}

.button-gray {
  background-color: #7B8080;
  color: #fefefe;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 0.9375rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px 10px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: none;
  position: relative;
  /* min-width: 150px;*/
  /* width: 200px;*/
}

.button-gray p {
  margin: 8px 0;
}

.button-gray img{
  display: inline;
  height: 26px;
  width: 26px;
  margin: 7px 0px 7px 7px;
  /* position: absolute;*/
  /* right: 5px;*/
  /* top: 5px;*/
}


/* Blog Einkaufswelten */

.blog--entry {
  width: 100% !important;
  height: 50% !important;
  background-color: #F3F3F3;
  padding: 0px 50px;
}

.blog--title h3 {
  color: #7B8080;
}

.emotion--blog .blog--entry {
  padding-left: 50px;
}

.blog--description {
  margin-bottom: 20px;
}

.blog--title-link-icon {
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}

/* Care Cards */

.six--emotion-category-teaser .category-teaser--title:after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 20px;
  background: url(/media/unknown/c1/c0/01/chevron-right-gray.svg);
}

.care-card .emotion--html{
  /* background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);*/
  /* background: -webkit-gradient(linear,  left top, left bottom,  from(rgba(255,255,255,1)),to(rgba(255,255,255,0)));*/
  /* background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);*/
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );*/
  padding: 2rem;
  border: 15px solid #DFDFDF;
  overflow: hidden;
  /* max-height: 400px;*/
  @media #{$large-down} {
    padding: 1rem;
  }
  @media #{$small-only} {
    background-image: none !important;
  }
}

.care-card:first-child .emotion--html {
  border-bottom: 0px !important;
}

.care-card {
  padding-bottom: 0 !important;
}

.care-card > .emotion--html a {
  background-color: #7B8080;
  color: #fefefe;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 0.9375rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 10px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: none;
  line-height: 1.5rem;
  /* position: absolute;*/
  /* bottom: 0;*/
  min-width: 150px;
  @media #{small-only} {
    width: 100%;
    font-size: 0.7875rem;
  }
}

.care-card .emotion--html a:after {
  content: "";
  width: 26px;
  height: 26px;
  background: url("/media/unknown/c1/c0/01/chevron-right-gray.svg");
  background-repeat: no-repeat;
  margin-left: 20px;
}

.care-card img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  /* opacity: 0.7;*/
  @media #{$medium-up} {
    /* height: 100%;*/
  }
  @media #{$small-only} {
    display: none;
  }
}

.emotion--mode-rows .emotion--category-teaser {
  @media #{$small-only} {
    height: 15rem;
  }
}

.emotion--category-teaser .category-teaser--link {
  background-size: auto 100%;
  background-position: top center;
}

.emotion--category-teaser .category-teaser--link:hover .category-teaser--caption {
  height: 40%;
  background-color: rgba(0,128,138,1) !important;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.emotion--category-teaser .category-teaser--link:hover .category-teaser--caption-bg {
  opacity: 0 !important;
}

.emotion--category-teaser .category-teaser--link .category-teaser--subtitle {
  opacity: 0;
  display: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.emotion--category-teaser .category-teaser--link:hover .category-teaser--subtitle {
  display: block;
  opacity: 1;
}

.six--emotion-category-teaser .category-teaser--caption {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  height: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -ms-flex-pack: distribute;
      -webkit-justify-content: space-around;
          justify-content: space-around;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0px 30px;
}

.six--emotion-category-teaser .category-teaser--title, .six--emotion-category-teaser .category-teaser--subtitle {
  white-space: normal;
}

.six--emotion-category-teaser .category-teaser--title {
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  /* margin-bottom: 10px;*/
  @media #{$large-down} {
    font-size: 15px;
  }
}

.six--emotion-category-teaser .category-teaser--subtitle {
  font-family: 'Lato', sans-serif;
  font-size: 300;
}

/* .emotion--category-teaser::after {*/
/*   display: block;*/
/*   position: absolute;*/
/*   height: 100%;*/
/*   width: 100%;*/
/*   bottom: 0;*/
/*   content: '';*/
/*   z-index: 1;*/
/*   @media #{$small-up} {*/
/*     background-image: -webkit-linear-gradient(left, #fcfcfc 0%, transparent 100%);*/
/*     background-image: linear-gradient(to right, #fcfcfc 0%, transparent 100%);*/
/*   }*/
/*   *//* @media #{$medium-only}{*//**/
/*   *//*   background-image: linear-gradient(to right, #fcfcfc 0%, transparent 100%);*//**/
/*   *//* }*//**/
/*   *//* @media #{$large-only}{*//**/
/*   *//*   background-image: linear-gradient(to right, #fcfcfc 0%, transparent 100%);*//**/
/*   *//* }*//**/
/*   *//* @media #{$xlarge-up} {*//**/
/*   *//*   background-image: linear-gradient(to right, #fcfcfc 0%, transparent 100%);*//**/
/*   *//* }*//**/
/* }*/

.card-contact {
  position: relative;
  width: 100%;
  height: 98%;
  color: #fefefe;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
          -webkit-align-items: flex-start;
          align-items: flex-start;
  min-height: 400px;
  padding: 50px;
  @media #{$xlarge-up} {
    position: absolute;
  }
  @media #{$large-down} {
    height: 400px;
  }
  @media #{$small-only} {
    padding: 30px;
  }
}

.card-contact-image {
  background-image: url('http://p396169.mittwaldserver.info/media/image/a9/de/a3/Screen-Shot-2017-04-20-at-09-45-19.png');
  height: 98%;
  width: 100%;
  position: absolute;
  @media #{$large-down} {
    height: 400px;
  }
}

.card-contact-overlay {
  background-color: #00808A;
  width: 100%;
  height: 98%;
  background-color: rgba(0, 128, 138, 0.8);
  position: absolute;
  @media #{$large-down} {
    height: 400px;
  }
}

.card-contact h5 {
  position: absolute;
  color: white;
  top: 20px;
  left: 50px;
  /* font-family: "Lato", Montserrat, Maven Pro, Arial, sans-serif;*/
  font-size: 1.25rem;
  font-weight: 300;
  @media #{$small-only} {
    left: 20px;
  }
}

.card-contact a {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      -webkit-align-self: center;
          align-self: center;
  margin: 0 auto;
  width: 100%;
  max-width: 250px;
}

.icon_fullscreen {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 50px;
}

.care-card p:not(:last-child) {
  z-index: 2;
  position: relative;
  @media #{$large-up} {
    max-width: 60%;
  }
  @media #{$medium-only} {
    max-width: 70%;
  }
  @media #{$small-only} {
    max-width: 100%;
  }
}

.care-card p:nth-child(2n) {
  @media #{$large-down} {
    text-align: justify;
  }
}

.care-card p:last-child {
  @media #{$small-only} {
    display: none;
  }
}

/* .emotion--mode-rows .emotion--category-teaser {*/
/*   height: auto;*/
/*   @media #{$small-only} {*/
/*     padding: 0 20px;*/
/*   }*/
/* }*/

.emotion--category-teaser .card-text-wrapper > p {
  max-width: 60%;
  @media #{$large-only} {
    max-width: 100%;
  }
}

/* .text-banner-slider-home .emotion--html {*/
/*   @media #{$medium-down} {*/
/*     padding: 0 20px;*/
/*   }*/
/* }*/

.description-tabs-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media #{$medium-down} {
    /* margin: 0 20px;*/
  }
}

#anmerkungaccordeon {
  /* @media #{$large-up} {*/
    display: none;
  /* }*/
}

.description-tabs-wrapper .category-description-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          -webkit-flex: 0 0 30%;
          flex: 0 0 30%;
  margin-right: 5%;
  line-height: 1.5;
  @media #{$medium-down} {
    display: none;
  }

  .entry--label {
    display: block;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .entry--content {
    line-height: 20px;
  }
}

.js--off-canvas-button .tab--header {
  margin-bottom: 0;
}

.description-tabs-wrapper .tab-menu--product {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 65%;
          -webkit-flex: 1 0 65%;
          flex: 1 0 65%;
  @media #{$medium-down} {
    /* display: none;*/
  }
}

.tab-beschreibung-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /*margin-bottom: 20px;*/
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.tab-beschreibung-wrapper:last-child .tab-beschreibung-text hr {
  display: none;
}

.tab-beschreibung-image-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
  /*height: 60px;*/
}

.tab-beschreibung-image-wrapper img {
  width: 100%;
  max-height: 60px;
  height: 60px;
}

.tab-beschreibung-wrapper .tab-beschreibung-text {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;

  img {
    mix-blend-mode: multiply;
    @media screen and (min-width: 1025px) {
      width: 80%;
    }
  }
}

.tab-beschreibung-wrapper .tab-beschreibung-text strong {
  font-size: 15px;
}

.product--details .content--description, .product--details .content--product-reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #F3F3F3;
}

.product--details .product--properties {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          -webkit-flex: 0 0 60%;
          flex: 0 0 60%;
  background: #F3F3F3;
  @media #{$small-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.tab--content {
  background: #F3F3F3;
}

.product--details .advantages {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  @media #{$small-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.product--details .advantages .entry--content>p {
  display: none;
}

.product--details .advantages .entry--content ul:not(:last-child):after {
  content: "";
  display: block;
  height: 1.7px;
  opacity: 0.2;
  margin: 20px 0;
  background: #ADAEAF;
}

/*.product--details .advantages .entry--content ul{
  list-style-image: url('/media/unknown/10/72/8d/check-green.svg');
}*/

.product--details .advantages .entry--content ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 10px;
}

.product--details .advantages .entry--content ul li:before {
  content: '';
  display: inline-block;
  height: 17px;
  background-size: contain;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20px;
          -webkit-flex: 0 0 20px;
          flex: 0 0 20px;
  margin-right: 10px;
  background-image: url(/media/unknown/10/72/8d/check-green.svg);
  background-repeat: no-repeat;
}

.advantages h4 {
  color: #008085;
  margin-top: 0;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: bolder;
}

.pflegehinweise {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.pflegehinweise .pflegebilder {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 15%;
          -webkit-flex: 0 0 15%;
          flex: 0 0 15%;
}

.pflegehinweise .pflegetext {
  -webkit-box-flex: 0;
      -ms-flex: 1 0 75%;
          -webkit-flex: 1 0 75%;
          flex: 1 0 75%;
}

.pflegehinweise .pflegetext li, .pflegehinweise .pflegebilder li {
  height: 60px;
  line-height: 60px;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.pflegehinweise img {
  width: 50px !important;
  height: 50px !important;
}

.product--properties-pflege {
  width: 100%;
  background: #F3F3F3;
}

.product--properties-pflege .tab-beschreibung-wrapper .tab-beschreibung-text {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  background: #F3F3F3;
}

.tab-beschreibung-wrapper .tab-beschreibung-text .pflegetext {
  padding-left: 20px;
}

.product--properties-pflege .panel {
  background: #F3F3F3;
}

.product--details .content--title {
  font-size: 0.75rem;
  color: #9AA0A4;
  margin: 0 0 1.5rem 0;
}

.single-product {
  /* background: #008085;*/
  background: rgb(0,128,133);
  background: -webkit-linear-gradient(left, rgba(0,128,133,1) 0%,rgba(0,86,94,1) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(0,128,133,1)),to(rgba(0,86,94,1)));
  background: linear-gradient(to right, rgba(0,128,133,1) 0%,rgba(0,86,94,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008085', endColorstr='#00565e',GradientType=0 );
}

.js--tab-menu .tab--link.is--active {
  background: #008085;
  color: white;
  border-bottom: none;
  margin-right: 1px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.js--tab-menu .tab--link.is--active:hover {
  color: white;
}

.js--tab-menu .tab--link.is--active:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 10px #008085;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.js--tab-menu .tab--link {
  background: #7B8080;
  color: white;
  border-bottom: none;
}

.js--tab-menu .tab--link:hover {
  color: white;
  background: #008085;
}

.js--off-canvas-button {
  border: none;
  border-top: 5px solid #008085;
  @media #{$medium-down} {
    background: #7B8080;
    border-top: none;
  }
}

.js--off-canvas-button:hover {
  background: #008085;
}

.js--off-canvas-button:hover .tab--title {
  color: white;
}

.js--off-canvas-button .tab--title {
  @media #{$medium-down} {
    color: white;
  }
}


.js--tab-menu .tab--link{
  border: none;
  margin-right: 1px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.optionalAccessories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media #{$large-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.optionalAccessory {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media #{$xlarge-up} {
    padding-right: 5%;
  }
  @media #{$large-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    padding-left: 0;
  }
  @media #{$medium-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.optionalAccessory:nth-child(even){
  border-left: 1px solid #F0F0F0;
  padding-left: 5%;
  padding-right: 0;
  @media #{$large-down} {
    border: none;
    padding-left: 0;
  }
}

.accessory-text {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          -webkit-flex: 0 0 60%;
          flex: 0 0 60%;
  @media #{$medium-down} {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 90%;
            -webkit-flex: 0 0 90%;
            flex: 0 0 90%;
    margin: 0 auto;
  }
}

.accessory-text h1 {
  font-weight: 400;
}

.accessory-text ul {
  list-style-type: none;
}

.accessory-image {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          -webkit-flex: 1 0 30%;
          flex: 1 0 30%;
  @media #{$small-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.accessory-image img {
  margin: 0 auto;
  max-width: 200px;
  max-height: 200px;
  @media #{$xlarge-only}{
    max-width: 150px;
    max-height: 150px;
  }
}
.accessory-image-wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid #F0F0F0;
  padding: 5px;
  margin: 0 auto;
  overflow: hidden;
  @media #{$xlarge-only} {
    width: 150px;
    height: 150px;
  }
}

.optionalAccessory:nth-child(odd) .accessory-image{
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
}

.optionalAccessory:nth-child(odd) .accessory-text {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
}

.optionalAccessory:nth-child(even) .accessory-text {
  @media #{$large-down} {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
  }
}

.optionalAccessories-heading {
  font-weight: 300;
  padding: 20px 0;
  margin-bottom: 0px;
  @media #{$medium-down} {
    /* margin-left: 20px;*/
  }
}

.similarProducts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-bottom: 50px;
  /* justify-content: space-between;*/
  @media #{$large-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;;
  }
}

.similarProduct {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(25% - 40px);
          -webkit-flex: 0 0 -webkit-calc(25% - 40px);
          flex: 0 0 calc(25% - 40px);
  background: -webkit-linear-gradient(bottom, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  background: -webkit-gradient(linear, left bottom, left top, from(white), color-stop(57.44%, #fcfcfc), to(#f3f3f3));
  background: linear-gradient(0deg, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  border: 2px solid #f3f3f3;
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
  @media #{$large-down} {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 46%;
            -webkit-flex: 0 0 46%;
            flex: 0 0 46%;
    margin: 20px 0;
  }
  @media #{$medium-down} {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    margin: 20px 0;
  }
}

.similarProduct:first-child {
  @media #{$large-up} {
    margin: 0 20px 0 0;
  }
}

.similarProduct:last-child {
  @media #{$large-up} {
    margin: 0 0 0 20px;
  }
}

.similarProductImage {
  padding: 25px;
  height: 20rem;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;

  a {
    max-height: 300px;
  }

  img {
    margin: 15px auto;
    max-height: 15rem;
  }
}

.similarProductText {
  padding: 20px;
  background: #eeeeee;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          -webkit-flex-grow: 1;
          flex-grow: 1;
}

.similarProductText>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.similarProductText h5 {
  margin: 0;
}

.similarProductText img {
  margin-left: 10px;
}

.image-slider--container {
  /* height: 95%;*/
  margin-bottom: 0;
}

.configurator--form label {
  cursor: pointer;
}

.option--input:disabled + label {
  cursor: not-allowed;
}

.configurator--form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media #{$medium-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  @media #{$xlarge-only} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.colorpicker {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  max-width: 100%;
  /* border-right: 1px solid #F5F5F5;*/
  /* padding-right: 20px;*/
  /* margin-right: 20px;*/
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    /* border-right: none;*/
    /* border-bottom: 1px solid #F5F5F5;*/
    /* margin: 10px 0;*/
  }
  @media #{$xlarge-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    /* border-right: none;*/
    /* border-bottom: 1px solid #F5F5F5;*/
    /* margin: 10px 0;*/
  }
}

.sizepicker {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  max-width: 100%;
  /* padding-left: 10%;*/
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    padding-left: 0;
    /* margin: 10px 0;*/
  }
  @media #{$xlarge-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    padding-left: 0;
    /* margin: 10px 0;*/
  }
}

.colors, .sizes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.variant--option {
  padding-right: 10px;
  padding-bottom: 10px;
  line-height: 30px;
}

.colorpicker {
  border-right: 1px solid #F5F5F5;
  padding-right: 20px;
  margin-right: 20px;
  @media #{$medium-down} {
    border-right: none;
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  @media #{$xlarge-only} {
    border-right: none;
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.sizes label {
  font-size: 1rem;
}

.sizes input:checked ~ label {
  font-weight: bold;
}

.colors input:checked ~ label {
  border: 3px solid white;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 1px #5D6266;
          box-shadow: 0 0 0 1px #5D6266;
}

.vorrat {
  color: #9AA0A4;
  font-size: 0.75rem;
}

.image-slider--item .image-media img {
  height: 100%;
}

.tab--content > [class^="content--"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #F3F3F3;
  padding: 30px 30px 30px 30px;
  padding: 1.875rem 1.875rem 1.875rem 1.875rem;
  border-top: 3px solid #008085;
  @media #{$small-only} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.option--label {
  border: none;
}
/*
.variant--option {
  width: auto;
  margin-right: 2%;
  position: relative;
  float: none;
}*/

.option--label {
  line-height: 2;
  padding: 0px 5px;
  padding: 0rem .3125rem;
}

.colors .variant--option .option--label {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 2px #5D6266;
          box-shadow: 0 0 0 2px #5D6266;
}

.option--input:hover {
  color: #008085;
}

.option--input:checked {
  width: auto;
  height: auto;
}

.option--input:checked ~ label {
  border-color: white;
  color: #008085;
}

.option--input:hover ~ label {
  border-color: white;
  color: #008085;
}

.product--description ul {
  margin: 0 0 1rem 0;
}

.tab-beschreibung-wrapper hr {
  border-color: #ADAEAF;
  opacity: 0.1;
  margin: 20px 0;
}

.sidebar--navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  @media #{$medium-down} {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.sidebar--categories-navigation .navigation--entry {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          -webkit-flex: 1 0 100%;
          flex: 1 0 100%;
  width: 100% !important;
}

.listing--actions .is--rounded {
  display: block;
}

.box--image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: none;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
  @media screen and (min-width: 641px) {
    width: 50%;
  }
  @media #{$xlarge-up} {
    width: 33.3%;
    margin: 0;
  }
}

.box--image .product--info {
  @media #{$medium-down} {
    padding-left: 0;
  }
}

.box--image .product--image {
  @media #{$medium-down} {
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem .3125rem 0rem;
    width: auto;
    position: relative;
    height: 200px;
    height: 12.5rem;
  }
}

.product--box {
  padding: 15px 0 15px 15px;
  height: auto;

  .box--image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: none;
  }

  &:after {
    content: none;
  }
}

.box--image .product--info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: -webkit-linear-gradient(bottom, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  background: -webkit-gradient(linear, left bottom, left top, from(white), color-stop(57.44%, #fcfcfc), to(#f3f3f3));
  background: linear-gradient(0deg, white 0%, #fcfcfc 57.44%, #f3f3f3 100%);
  /* height: 100%;*/
}

.product--box .box--content {
  border: 1px solid #f3f3f3;
  padding: 0;
  background: #eeeeee;
  height: auto;
  width: 100%;
}

.product--box .product--title-wrapper {
  padding: 20px;
  background: #eeeeee;
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  @media #{$small-only} {
    padding: 10px;
  }
}

.product--box .product--title {
  margin: 0;
  overflow: visible;
  min-height: 2.5rem;
  height: auto;
}

.product--box .product--title+img {
  @media #{$small-only} {
    display: none;
  }
}

.product--box .product--title:hover {
  color: #008085;
}

.sidebar--navigation .navigation--link.is--active {
  height: auto;
}

.sidebar--navigation .navigation--link:hover {
  color: #008085;
}

.sidebar-main {
  width: 80%;
}

.sidebar-main .mobile--switches, .sidebar-main .filter--container, .sidebar-main .navigation--smartphone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.navigation--smartphone {
  @media #{$large-up} {
    display: none;
  }
}

.filter-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          -webkit-flex: 1 0 100%;
          flex: 1 0 100%;
  width: auto;
  margin-left: 0;
  float: none;
}

.filter-panel--media-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 0;
}

.filter-panel--media-list:after {
  display: none;
}

.filter-panel--media-option {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 46%;
          -webkit-flex: 0 0 46%;
          flex: 0 0 46%;
  padding: 0 !important;
  width: auto;
  max-width: none;
  max-height: 100px;
  height: auto;
  margin: 0 0 5% 2%;
  border: none !important;
}

.option--container {
  width: auto;
  /* height: 100%;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: white;
  padding: 10px;
}

.option--container-facet-value-list {
  background: none;
}

.option--container-facet-value-list .filter-panel--checkbox {
  background: white;
}

.filter-panel--option:not(:last-child) .option--container-facet-value-list {
  border-bottom: 1px solid white;
}

.filter-panel--content {
  padding: 0;
  max-height: none;
  overflow: visible;
}

.filter--active-container {
  /* display: none !important;*/
}

#filter {
  display: block !important;
  width: 100%;
}

.listing--actions {
  padding-top: 0;
  border: none;
}

.filter--actions {
  margin-bottom: 0;
  width: 107.5%;
  margin-left: -3.5%;
  border: none;
  /* display: none;*/
  @media #{$large-down} {
    width: 100%;
    margin-left: 0;
  }
}

.filter-panel--label {
  padding: 0 0 0 10px;
}

.filter-panel--title {
  background-color: #CBCBCB;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 30px 0 0 0;
  color: black;
  overflow: inherit;
  white-space: normal;
  cursor: auto;

}

.filter-panel--title>b {
  line-height: 30px;
}

.filter-panel--title .tooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.filter-panel--option .tooltip {
  position: absolute;
  right: 10px;
  line-height: 0;
}

.filter-panel--title .tooltip-image, .filter-panel--option .tooltip-image {
  display: inline;
  max-height: 20px;
  width: 20px;
  top: 6px;
  right: 5px;
}

.tooltip {
  line-height: 40px;
}

.tooltip-text {
  width: 200px;
  height: auto;
  background-color: #008080;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 35px;
  visibility: hidden;
  line-height: 1.2;
  z-index: 1;
  text-align: left;
  padding: 10px;

  @media #{$large-down} {
    top: 40px;
    right: -5px;
    left: auto;
  }
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 5%;
  left: -9%;
  margin-left: 0px;
  border-width: 10px;
  border-style: solid;
  border-color: #008080 transparent transparent transparent;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);

  @media #{$large-down} {
    top: -20px;
    right: 5px;
    left: auto;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

.filter-panel--title .tooltip:hover .tooltip-text, .filter-panel--option .tooltip:hover .tooltip-text{
  visibility: visible;
}

.filter-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: #008089;
}

.filter-overlay-text {
  color: white;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.filter-panel--media-option:hover .filter-overlay {
  opacity: 0.9;
}

.filter-panel--media-option input[type="checkbox"]:checked + label + .filter-overlay {
  opacity: 0.9;
}

.filter-panel--media-label {
  border: none;
  height: auto;

  .filter-panel--media-image {
    max-height: 75px;
  }
}

.action--filter-options {
  background: #F2F2F2;
  width: 100%;
}

.is--ctl-listing .sidebar-main, .is--ctl-forms .sidebar-main, .is--ctl-tellafriend .sidebar-main, .is--ctl-newsletter .sidebar-main, .is--ctl-sitemap .sidebar-main, .is--ctl-custom .sidebar-main, .is--ctl-note.is--user .sidebar-main, .is--ctl-address .sidebar-main, .is--ctl-account.is--user .sidebar-main {
  width: 22%;
  overflow: visible;
  @media #{$large-down} {
    width: 75%;
  }
}

.off-canvas {
  @media #{$large-down} {
    display: block;
    padding-bottom: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    /* width: 100%;*/
  }
}

.is--ctl-listing .sidebar-main + .content--wrapper, .is--ctl-forms .sidebar-main + .content--wrapper, .is--ctl-tellafriend .sidebar-main + .content--wrapper, .is--ctl-newsletter .sidebar-main + .content--wrapper, .is--ctl-sitemap .sidebar-main + .content--wrapper, .is--ctl-custom .sidebar-main + .content--wrapper, .is--ctl-note.is--user .sidebar-main + .content--wrapper, .is--ctl-address .sidebar-main + .content--wrapper, .is--ctl-account.is--user .sidebar-main + .content--wrapper {
  margin-left: 25%;
  @media #{$large-down} {
    margin-left: 0;
    padding: 0 20px;
  }
}

.action--filter-options .filter--container {
  padding: 0 5%;
  @media #{$large-down} {
    padding: 0;
  }
}

.filter-panel--option {
  border: none !important;
  padding: 0 !important;
}

.filter-panel--media-option {
  margin: 4% 0;
}

.box--image .product--image {
  height: 23rem;
  @media #{$small-only} {
    height: 15rem;
  }
}

/* .category-banner-wrapper img {*/
/*   width: 100%;*/
/*   @media #{$xlarge-up} {*/
/*     height: 400px;*/
/*   }*/
/*   @media #{$large-down} {*/
/*     height: 250px;*/
/*   }*/
/* }*/
/**/
/* .custom-content-banner img {*/
/*   width: 100%;*/
/*   @media #{$xlarge-up} {*/
/*     height: 300px;*/
/*   }*/
/*   @media #{$large-down} {*/
/*     height: 200px;*/
/*   }*/
/* }*/

.category-banner-wrapper .banner--container {
  margin: 0;
}

.product--details .configurator--form, .product--details .buybox--form {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.off-canvas .close--off-canvas {
  background-image: none;
  background-color: #008085;
}

.content--home {
  margin-top: 0;
}

.listing-care-card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 100px;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.listing-care-card-wrapper .care-card {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
          -webkit-flex: 1 0 50%;
          flex: 1 0 50%;
  @media #{$large-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.listing-care-card-wrapper .emotion--html {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.listing-care-card-wrapper h2 {
  margin-top: 0;
  z-index: 2;
  font-weight: 400;
}

.listing-care-card-wrapper a {
  z-index: 2;
}

.listing-care-card-wrapper .emotion--html a::after {
  top:10px;
}

.sidebar--categories-wrapper .service--list {
  @media #{$medium-down} {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
    background: white;
  }
}

.sidebar--categories-wrapper .service--list .service--entry {
  padding: 15px 20px;
  border-bottom: 1px solid #dadae5;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
}

.sidebar--categories-wrapper .service--list .service--entry a {
  color: #5f7285;
}

.service--list .service--entry {
  img {
    height: 21px;
    width: 21px;
    margin-right: 10px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    @media #{$xlarge-up} {
      display: none;
    }
  }
  img.rotate {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

.sidebar--categories-wrapper .service--list .service--entry a:hover {
  color: #008080;
}

.sidebar--categories-wrapper .productmenu-button {
  width: 100%;
  padding: 20px;
  text-align: left;
}

.off-canvas .sidebar--navigation .navigation--link {
  padding: 0 .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
}

.off-canvas .sidebar--navigation .navigation--link img {
  max-height: 40px;
  display: inline-block;
}

.offcanvas--overlay {
  width: 100%;
}

.action--filter-options .filter--btn-apply {
  background: #008085;
  color: white;
  width: 101.5%;
  border-radius: 0;
  margin-top: -4%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  display: block;
  @media #{$large-down} {
    width: 100%;
    margin: 0;
  }
}
.action--filter-options .filter--close-btn {
  background: #008085;
  color: white;
  width: 103%;
  border-radius: 0;
  margin-top: -4%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  @media #{$large-down} {
    width: 100%;
    margin: 0;
    display: block;
  }
}

.filter--close-btn {
  @media #{$xlarge-up} {
    display: none;
  }
}

.is--ctl-blog .sidebar-main, .is--ctl-note .sidebar-main, .is--ctl-index .sidebar-main, .is--ctl-detail .sidebar-main, .is--ctl-search .sidebar-main, .is--ctl-checkout .sidebar-main, .is--ctl-register .sidebar-main, .is--ctl-campaign .sidebar-main, .is--ctl-account.is--act-logout .sidebar-main, .is--ctl-account.is--act-password .sidebar-main, .is--ctl-account.is--act-resetPassword .sidebar-main, .is--ctl-listing.is--no-sidebar .sidebar-main {
  @media #{$xlarge-up} {
    display: none;
  }
  @media #{$large-down} {
    display: block;
  }
}

.shop--navigation .entry--menu-left {
  @media #{$xlarge-up} {
    display: none;
  }
  @media #{$large-down} {
    display: block;
  }
}

.hideforxlargeup {
  @media #{$xlarge-up} {
    display: none;
  }
}

.offcanvas--overlay .is--display-button {
  color: #008085;
}

.logo--link img {
  height: 50px;
}

.navigation--smartphone .entry--close-off-canvas {
  background: #008085;
}

.navigation--smartphone .entry--close-off-canvas .icon--arrow-right {
  margin: .8rem 0rem 0rem 1rem;
}

.filterNav {
 position: fixed;
 bottom: 0;
 width: 100vw;
 left: 0;
 background-color: #D4D4D4;
 height: 40px;
 z-index: 99999999;
 @media #{$xlarge-up} {
   display: none;
 }
}

.filterNav-sidebar {
  /* width: 75%;*/
  background: #f5f5f8;
  -webkit-transform: translateX(-110%);
          -ms-transform: translateX(-110%);
      transform: translateX(-110%);
  @media #{$xlarge-up} {
    display: none;
  }
}

.filterNav-sidebar.show {
  -webkit-transform: translate(-20px);
      -ms-transform: translate(-20px);
          transform: translate(-20px);
}

#openNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  justify-content: flex-end;
}

.filterNav img {
  margin: 10px;
  height: 25px;
  width: 25px;
}

.filterNav h5 {
  margin: 0;
  font-weight: 400;
}

.originalSidebar {
  @media #{$large-down} {
    display: none;
  }
}

.standardSidebar {
  @media #{$xlarge-up} {
    display: none;
  }
}

.termine-aktuelles-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 50px 0 100px 0;
  @media #{$large-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  @media (max-width: 1260px) {
    padding: 0 20px;
  }
}

.termine {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          -webkit-flex: 0 0 60%;
          flex: 0 0 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
  @media #{$large-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  /* @media #{$medium-down} {*/
  /*   padding: 20px;*/
  /* }*/
}

.termine hr, .aktuelles hr {
  height: 1px;
  margin: 10px 0 10px 0;
  width: 100%;
  background: #E2E2E2;
  border: none !important;
  @media #{$medium-down} {
    width: 95%;
  }
}

.aktuelles hr {
  background: white;
}

.aktuelles hr:last-child {
  display: none;
}

.termine h5, .aktuelles>h5 {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 15px;
}

.aktuelles {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  @media #{$large-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  /* @media #{$medium-down} {*/
  /*   padding: 20px;*/
  /* }*/
}

.aktuelles-wrapper {
  background-color: #F3F3F3;
  padding: 50px;
  @media #{$medium-down} {
    padding: 20px;
  }
}

.aktuelles-wrapper img {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-right: 10px;
}

.termine-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
              justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  @media #{$medium-down} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-bottom: 20px;
  }
}

.termine .termine-wrapper:first-of-type h4 {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.termine-wrapper h4 {
  display: inline-block;
  color: #00808A;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.termine-wrapper img {
  display: inline-block;
  height: 35px;
  margin-right: 10px;
}

.termine-wrapper .cal-export img {
  margin-right: 5px;
}

.termine-wrapper .cal-export hr {
   margin-right: 5px;
 }

.termine-wrapper p {
  margin: 0;
}

.termine-wrapper .vertical-line {
  height: 40px;
  margin: 0px;
  width: 1px;
  background: #E2E2E2;
  border: none;
  /* @media #{$medium-down} {*/
  /*   display: none;*/
  /* }*/
}

.termine-wrapper .cal-export {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          -webkit-flex: 0 0 30%;
          flex: 0 0 30%;
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.termine-wrapper .cal-export a {
  font-size: 0.7rem;
  text-decoration: underline;
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.termine-wrapper .termin-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          -webkit-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
          -webkit-flex: 0 0 35%;
          flex: 0 0 35%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  @media #{$medium-down} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    margin: 20px 0;
    /* justify-content: flex-start;*/
  }
}

.aktuelles-wrapper h5 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 300;
}

.listing--content {
  @media screen and (max-width: 1259px) {
    padding-top: 1.8125rem;
  }

  @media #{$xlarge-up} {
    width: 75%;
    margin: 0;
    float: right;
  }
}

.sizes-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
}

.sizes-table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
}

.sizes-table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  line-height: 50px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  @media #{$small-only} {
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.tab--content .content--description-size {
  padding: 0.5rem;
}

.content--description-size .product--properties {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
}

.content--description-size .tab-beschreibung-wrapper .tab-beschreibung-text {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
}

.sizes-table-col:first-child {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 15%;
          -webkit-flex: 0 0 15%;
          flex: 0 0 15%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
  @media #{$small-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.sizes-table-col {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
          -webkit-flex: 0 0 10%;
          flex: 0 0 10%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
}

.sizes-table-col img {
  height: 40px;
  -ms-flex-item-align: center;
      -webkit-align-self: center;
          align-self: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-left: 20px;
  @media #{$small-only} {
    margin: 0;
  }
}

.sizes-table-col p {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0;
  @media #{$small-only} {
    margin-right: 20px;
  }
}

.sizes-table-col {
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.sizes-table-col.kids {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 85%;
          -webkit-flex: 0 0 85%;
          flex: 0 0 85%;
  @media #{$small-only} {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    font-size: 13px;
  }
}

.panel--dot-nav .dot--link.is--active, .panel--dot-nav a.is--active {
  border-color: #008087;
  background: #008087;
}

.image-slider--container .arrow {
  display: none;
}

.image-slider--container .arrow:hover {
  color: #008087;
  border-color: rgba(0,128,133,0.5);
}

.filter-panel--checkbox input[type="checkbox"]:checked ~ .checkbox--state {
  background-color: #008087;
  background-image: none;
}

.filter-panel--option .option--container:hover .filter-panel--checkbox, .filter-panel--option .option--container:hover .filter-panel--label, .filter-panel--checkbox:hover, .image-slider--thumbnails .thumbnail--link.is--active {
  border-color: #008087;
}

.filter-panel--option .option--container:hover .filter-panel--label {
  color: #008087;
}

/* .listing--wrapper {*/
/*   @media #{$large-down}{*/
/*     padding: 0 20px;*/
/*   }*/
/* }*/

.weiter-button {
  color: #3f4c58;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.main-search--results {
  @media #{$small-only} {
    width: 80vw;
  }
}

.results--list .entry--all-results-number {
  color: #008085;
}

.header-main .logo-main .logo--link {
  @media #{$xlarge-up} {
    float: right;
  }
}

.mobile-sidebar-category-image {
  max-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-right: 10px;
  width: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.footer-main .column--content {
  display: block;
}

.footer-main .column--headline::after {
  display: none;
}

.variant--name {
  display: inline-block;
  font-weight: bold;
  margin: 0rem 0rem .3125rem 0rem;
}

.product--configurator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

textarea:focus, input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="tel"]:focus {
  border-color: #00808A;
}

.banner-slider--banner .banner-slider--content {
  padding: 0 20px;
  max-width: 960px;
  margin: 0 auto;
}

.banner-slider--description span {
  font-size:-webkit-calc(12px + 0.4vw) !important;
  font-size:calc(12px + 0.4vw) !important;
  /* font-size: 2vw !important;*/
}

.banner-slider--headline {
  font-size:-webkit-calc(16px + 1.5vw) !important;
  font-size:calc(16px + 1.5vw) !important;
  /* font-size: 4vw !important;*/
}

.js--tab-menu .tab--container-list .tab--container {
  border: none;
}

.product--box .product--title:last-child {
  font-weight: regular;
}

.first-listing-heading {
  margin: 0;
  font-size: 0.875rem;
  line-height: 20px;
}

.second-listing-heading {
  margin: 0;
  font-weight: 400;
  line-height: 20px;
}

.description-tabs-wrapper .entry--content p span:first-child {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
}

.description-tabs-wrapper .entry--content p span:last-child {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
}

.icon-produktvorschlag {
  display: inline;
  max-width: 15px;
  margin-right: 5px;
  /* margin-top: 20px;*/
}

.produktvorschlag-link {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
}

.contact-care-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          -webkit-align-items: stretch;
          align-items: stretch;
}

.care-cards {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 66%;
          -webkit-flex: 1 0 66%;
          flex: 1 0 66%;
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-fle#xbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  @media #{$large-down}{
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

.contact-card-wrapper {
    -webkit-box-flex: 1;
      -ms-flex: 1 0 32%;
          -webkit-flex: 1 0 32%;
            flex: 1 0 32%;
  position: relative;
  @media #{$large-down}{
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.contact-card-wrapper>p a {
  color: #5f7285;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 35px;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  z-index: 99999;
}

.back-to-top.show {
  opacity: 1;
}

.hilfe-badge-wrapper {
  position: fixed;
  left: -90px;
  top: 40%;
  width: 220px;
  height: 42px;
  z-index: 99999;
  @media #{$large-down} {
    display: none;
  }
  @media screen and (max-width: 1300px) {
    left: -webkit-calc(100%/2 - 110px);
    left: calc(100%/2 - 110px);
    top: auto;
    bottom: 0;
  }
}

.hilfe-badge {
  background-color: #008085;
  color: white;
  padding: 5px 10px;
  height: 42px;
  -webkit-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
      transform: rotate(270deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
  @media screen and (max-width: 1300px) {
    -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
        transform: rotate(0deg);
  }
}

.hilfe-badge h4 {
  margin: 0;
  color: white;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
}

.hilfe-badge img {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
  @media screen and (max-width: 1300px) {
    -webkit-transform: rotate(0deg) !important;
        -ms-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
  }
}

.content--breadcrumb .breadcrumb--entry:last-child, .content--breadcrumb .breadcrumb--separator:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.content--breadcrumb .breadcrumb--list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
}

.content--breadcrumb .breadcrumb--link, .content--breadcrumb .breadcrumb--separator {
  padding: 0px 10px;
}

.fachhandler-werden {
  @media #{$xlarge-up} {
    position: absolute;
    bottom: -4%;
  }
  @media #{$large-down} {
    padding-left: 20px;
  }
}

.listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.search--results {
  .listing {
    margin: 0;

    .product--info, .product--title-wrapper {
      height: 100%;
    }
  }
}

.search-result--link .entry--price {
  display: none;
}

.results--list .is--active, .results--list .list--entry:hover {
  color: #008085;
}

.results--list .is--active .entry--name, .results--list .list--entry:hover .entry--name, .results--list .is--active .entry--price, .results--list .list--entry:hover .entry--price {
  color: #008085;
}

textarea, input[type="text"], input[type="search"], input[type="password"], input[type="number"], input[type="email"], input[type="tel"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 19px;
  line-height: 1.1875rem;
  font-size: 14px;
  font-size: .875rem;
  padding: 10px 10px 9px 10px;
  padding: .625rem .625rem .5625rem .625rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #f8f8fa;
  border: none;
  color: #414549;
  text-align: left;
  border-bottom: 1px solid #dadae5;
  font-style: italic;
}

.forms--content {
  /* max-width: 600px;*/
  margin-top: 0;
}

/* .forms--content .field--select:last-child {*/
/*   width: 400px;*/
/* }*/
/**/
/* .forms--content .field--select:first-child {*/
/*   width: 200px;*/
/* }*/

.sub-pages {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  @media #{$xlarge-up} {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 9999;
    background: rgb(226,226,226);
    background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%,rgba(255,255,255,1) 96%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(226,226,226,1)),color-stop(96%, rgba(255,255,255,1)));
    background: linear-gradient(to bottom, rgba(226,226,226,1) 0%,rgba(255,255,255,1) 96%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#ffffff',GradientType=0 );
    /* padding: 20px;*/
    border: 1px solid #E2E2E2;
    border-top: none;
    text-align: left;
  };
  @media #{$large-down} {
    /* padding-left: 20px;*/
    display: none;
  };
}

.sub-pages.show {
  display: block;
}

.sub-pages-container {
  position: relative;

  .service--entry.hover {
    background: #e2e2e2;
  }
}

.sub-pages li {
  min-height: 35px;
  @media #{$xlarge-up} {
    padding: 0px 20px;
  }
  @media #{$large-down} {
    padding-left: 20px;
  }
}

.sub-pages .service--link {
  line-height: 35px;
  white-space: nowrap;
}

.sub-pages li:hover {
  background: #008085;

  .service--link {
    color: #ffffff;
  }
}

.category-banner-wrapper .banner--container .banner-image {
  position: relative;
  background-size: cover;
  background-position: center;
  @media #{$xlarge-up} {
    min-height: 400px;
    background-position: 0% 25%;
  }
  @media #{$large-down} {
    min-height: 250px;
  }
}

.custom-content-banner .banner-image {
  position: relative;
  background-size: cover;
  background-position: center;
  @media #{$xlarge-up} {
    min-height: 300px;
  }
  @media #{$large-down} {
    min-height: 200px;
  }
}

.category-banner-wrapper .banner--container .banner-text {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 50px 40% 50px 50px;
  background : -webkit-linear-gradient(15.04deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  background : -webkit-gradient(linear,8.27% 95.17% ,92.48% 18.13% ,color-stop(0,rgba(128, 30, 80, 0.5) ),color-stop(0.235,rgba(102, 46, 88, 0.5) ),color-stop(0.733,rgba(37, 87, 108, 0.5) ),color-stop(1,rgba(0, 110, 120, 0.5) ));
  background : -ms-linear-gradient(15.04deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#801E50', endColorstr='#006E78' ,GradientType=0)";
  background : linear-gradient(74.96deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  @media #{$xlarge-up} {
    padding: 30px 30% 30px 30px;
  }
  @media #{$large-down} {
    padding: 10px;
  }
}

.custom-content-banner .banner-text {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          -webkit-align-items: end;
          align-items: end;
  padding: 50px 40% 50px 50px;
  background : -webkit-linear-gradient(15.04deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  background : -webkit-gradient(linear,8.27% 95.17% ,92.48% 18.13% ,color-stop(0,rgba(128, 30, 80, 0.5) ),color-stop(0.235,rgba(102, 46, 88, 0.5) ),color-stop(0.733,rgba(37, 87, 108, 0.5) ),color-stop(1,rgba(0, 110, 120, 0.5) ));
  background : -ms-linear-gradient(15.04deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#801E50', endColorstr='#006E78' ,GradientType=0)";
  background : linear-gradient(74.96deg, rgba(128, 30, 80, 0.5) 0%, rgba(102, 46, 88, 0.5) 23.5%, rgba(37, 87, 108, 0.5) 73.3%, rgba(0, 110, 120, 0.5) 100%);
  @media #{$xlarge-up} {
    padding: 50px;
  }
  @media #{$large-down} {
    padding: 30px 50px;
  }
}




.category-banner-wrapper .banner--container .banner-text .category--teaser {
  border: none;
  color: white;
  text-shadow: 0 0 20px black;
  margin: 0;
  background: transparent;
}

.category-banner-wrapper .banner--container .banner-text .category--teaser h1, .custom-content-banner .banner-text h1 span {
  color: white !important;
  font-family: "Montserrat";
  font-weight: bold;
  @media #{$large-up} {
    font-size: 30px;
    line-height: 35px;
  }
  @media #{$medium-down} {
    font-size: 14px;
    line-height: 20px;
  }
}

.category-banner-wrapper .banner--container .banner-text .category--teaser p, .custom-content-banner .banner-text p, .custom-content-banner .banner-text p em {
  color: white !important;
  font-family: "Lato";

  @media #{$xlarge-up} {
    font-size: 20px;
    line-height : 27px;
  }
  @media #{$large-down} {
    font-size: 14px;
    line-height: 20px;
  }
  @media #{$small-only} {
    font-size: 10px;
    line-height: 15px;
  }
}

.blog--box .blog--box-metadata {
  border: none;
}

.blog--box-headline .panel--title {
  color: #414549;
}

.blog--box-header {
  .subtitle {
    font-weight: 200;
    color: #7B8080;
    line-height: 16px;
    padding: 0;
  }
}

.category--teaser .hero--headline {
  font-size: 24px;
  color: #008088;
  font-weight: 300;
  padding: 1.5rem 0rem 0rem 0rem;
}

.category--teaser .hero-text {
  padding: 1rem 2.5rem 1.625rem 2.5rem;
  max-width: 700px;
}

.blog--box-readmore > a {
  background-color: #7B8080;
  color: #fefefe;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 5px 10px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: none;
  line-height: 1.5rem !important;
  min-width: 130px;
  border-radius: 0px;
}

.blog--box-readmore > a:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url(/media/unknown/c1/c0/01/chevron-right-gray.svg);
  background-repeat: no-repeat;
  margin-left: 15px;
}

.blog--box-readmore > a:hover {
  color: #fefefe;
  background-color: #7B8080;
}

.blog--detail .blog--detail-headline {
  font-size: 24px;
  color: #008088;
  font-weight: 300;
}

.blog--detail .blog--detail-description h2 {
  line-height: 1rem;
  @media #{$medium-down} {
    line-height: 1.4rem;
  }
}
/* */
/* .custom-page--content .forms--content .js--fancy-select .js--fancy-select-text {*/
/*   padding: 0;*/
/* }*/
/**/
/* .custom-page--content .forms--content .forms--content .field--select {*/
/*   margin-bottom: 5px;*/
/* }*/
/**/
/* .custom-page--content .forms--content .js--fancy-select .js--fancy-select-trigger {*/
/*   height: 100%;*/
/* }*/
/**/
/* .custom-page--content .forms--content .js--fancy-select-trigger, .js--fancy-select-trigger i {*/
/*   top: 7px;*/
/*   position: absolute;*/
/*   right: 6px;*/
/*   font-size: 6px;*/
/* }*/

.forms--content .btn {
  float: left;
  border-radius: 0;
}

.forms--content .btn {
  border-color: inherit;
  color: white;
}

.forms--content .btn.is--icon-right [class^="icon--"] {
  height: 20px;
  line-height: 32px;
  line-height: 2rem;
  margin: -16px 0px 0px 0px;
  margin: -1rem 0rem 0rem 0rem;
  right: 12px;
  background: #00565E;
  right: 10px;
  border-radius: 5px;
  font-weight: normal;
  display: block;
  position: absolute;
  top: 25px;
  width: 20px;
}

/* .icon---right:before {*/
/*   position: absolute;*/
/*   top: -5px;*/
/*   right: 7px;*/
/* }*/

.forms--title {
  font-size: 20px;
  font-weight: 600;
}

form#support #blub:before {
  content: 'test';
  background-image: url('https://www.google.de/url?sa=i&rct=j&q=&esrc=s&source=images&cd=&cad=rja&uact=8&ved=0ahUKEwjE_Jrs35zWAhVRnRQKHbL_ATEQjRwIBw&url=https%3A%2F%2Fwww.pexels.com%2Fsearch%2Fcat%2F&psig=AFQjCNGppeknWfZfBW_YKdu8JrYdSX3E8g&ust=1505206105998436');
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: cover;
}

.suprima-form {
  margin-bottom: 150px;

  @media #{$medium-down} {
    margin-bottom: 0;
  }

  .form-article-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

    h4 {
      margin-top: 0;
    }

    .heading {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 100%;
          -ms-flex: 1 0 100%;
              flex: 1 0 100%;
    }

    div {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 20%;
              -webkit-flex: 0 0 20%;
              flex: 0 0 20%;
      margin-bottom: 20px;
      padding: 0 10px;
      @media #{$small-only} {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
      }
      @media #{$medium-only} {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3%;
            -ms-flex: 0 0 33.3%;
                flex: 0 0 33.3%;
      }
      @media #{$large-only} {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
      }
      @media #{$xlarge-only} {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
                flex: 0 0 20%;
      }
    }
  }
  .js--fancy-select {
    max-height: 2.625rem;
    margin-bottom: .9375rem;
    width: 100%;
    max-width: 100%;
    background-image: none;
    background: #f8f8fa;
    border: none;
    border-bottom: 1px solid #dadae5;

    .js--fancy-select-trigger {
      border: none;

      i {
        font-size: 7px;
      }
    }
  }

  textarea {
    min-height: 150px;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 5px;
  }

  input[type="submit"] {
    background-color: #008085;
    color: white;
    padding: 5px 10px;
    margin-bottom: 0;
    height: 42px;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-appearance: button-bevel;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-weight: bold;
  }

  .submit-wrapper::after {
      content: "";
      width: 26px;
      height: 26px;
      background: url(/media/unknown/c1/c0/01/chevron-right-gray.svg);
      background-repeat: no-repeat;
      margin-left: 20px;
  }
}

.custom-sidebar {
  .link-sidebar-kundenservice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
    color: #414549;

    img {
      max-height: 20px;
      margin-right: 5px;
    }
  }

  .phone-sidebar {
    font-size: 22px;
    color: #414549;
    margin-bottom: 20px !important;

    .number {
      color: #801E50;
      font-weight: bold;
    }
  }

  .kundenbetreuung {
    font-size: 20px;
    color: #414549;
    font-weight: 300;
  }

  .wirsindda {
    font-size: 20px;
    color: #7B8080;
    font-weight: 300;
  }
}

#storeLocator #searchWrap #searchOptions #storeSelect .saveadd {
  margin-left: 10px;
  @media #{$medium-down} {
    margin-left: 0;
  }
}

/* .kplngisd-circle {*/
/*   width: 1.5rem;*/
/*   height: 1.5rem;*/
/* }*/
/**/
/* .kplngisd-icon {*/
/*   width: 1.5rem;*/
/*   height: 1.5rem;*/
/*   padding: .275rem 0rem 0rem .2625rem;*/
/*   font-size: 22px;*/
/*   font-size: .975rem;*/
/* }*/

.configurator--variant .option--input:checked ~ label {
  border-color: white;
  color: white;
}

.configurator--variant .option--input:checked ~ label.fuss {
  color: #008085;
}

.configurator--variant .option--input:hover ~ label {
  border-color: white;
  color: white;
}

.configurator--variant .option--input:hover ~ label.fuss {
  color: #008085;
}

.product--details .product--configurator .reset--configuration {
  display: none;
}

.listing-colors-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 501;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;

  .expcolorbutton {
    border: 1px solid black;
  }
}

.tab-beschreibung {
  font-weight: bold;
}

.banner-slider--subTitle {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.custom-sidebar table {
  margin-bottom: 10px;
}

.custom-sidebar table tr td {
  background-color: transparent;
  padding: 0px;
  padding-right: 5px;
}

.content--custom table tr td {
  background-color: transparent;
}

.is--ctl-blog .category-banner-wrapper .banner--container .banner-text .category--teaser {
  display: none;
}
